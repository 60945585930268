<template>
    <ModalSide class="creator-modal" ref="modal" @creator-next-page-left="nextProductListPage(0)"
        @creator-next-page-right="nextProductListPage(0)" @close="close">
        <header v-if="data_info && !$store.state.is_loading">
            <div class="title temp-css-in-title">
                <span>{{ data_info.first_name + ' ' + data_info.last_name }}</span>
            </div>
            <div class="icon-box">
                <div class="icon" v-if="$store.state.user_data.is_motom_internal_user" @click="tempBtnForFb()" >
                    <SvgIcon name="refrash" />
                </div>
                <div class="icon" v-if="$store.state.user_data.is_motom_internal_user && data_info.email"
                    @click="emailTo(data_info.email)" v-tippy="{ content: data_info.email, delay: [500, 20] }">
                    <SvgIcon name="email" />
                </div>
                <div class="icon" v-if="data_info.share_link && data_info.anchor_page_status === 100"
                    @click="viewStorefront(data_info.share_link)">
                    <SvgIcon name="storefront" />
                </div>
                <div class="icon" v-if="data_info.status_text !== 'joined' && $store.state.role_id < 3"
                    :class="['edit']" @click="editInfo">
                    <SvgIcon name="pen" />
                </div>
                <div class="icon" v-if="data_info.share_link && data_info.anchor_page_status === 100" @click="share">
                    <SvgIcon name="share-2" />
                </div>
                <div class="icon close" @click="close">
                    <SvgIcon name="close" />
                </div>
            </div>
        </header>
        <Loading class="static" v-if="temp_loading && $store.state.is_loading" />

        <div class="box" v-if="!temp_loading && !$store.state.is_loading">
            <Loading class="static" v-if="!data_info" />
            <div v-if="data_info" class="creator-info-box" :class="{'no-program' : !$store.state.user_data.merchant_account.is_enable_ambassador_program || !data_info.ambassador_program_tag}">
                <figure class="avatar">
                    <img v-if="data_info.userProfile" :src="data_info.userProfile.avatar_full_url" @error="imageError($event)" />
                </figure>
                <div class="creator-info">
                    <div
                        :class="['creator-data-box', user_handle_value.only_one && 'creator-data-box-only-one', user_handle_value.no_one && 'creator-data-box-no-one']">
                        <!--Storefront-->
                        <CreatorHeaderSmallBox :data_info="data_info" :top_value="'Storefront'"
                            :content_value="header_content_value.storefront" :had_slot="true"
                            :had_inf="$store.state.user_data">
                            <template v-slot:head>
                                <div class="right">
                                    <div v-if="data_info.anchor_page_status === -10" class="resend"
                                        @click="resendInvite">
                                        Resend</div>
                                    <!-- <div class="store-date" v-else>{{ $filters.momentFormat(data_info.disabled_at,'ll') }}</div> -->
                                    <div class="value value-act"
                                        :class="[data_info.anchor_page_status_text.toLowerCase()]" @click="clickSwitch">
                                        {{ data_info.anchor_page_status_text }}
                                    </div>
                                </div>
                            </template>
                            <template v-slot:item>
                                <div class="item">
                                    <div class="content">{{ data_info.status_text }} ● <div class="link-box"
                                            v-if="data_info.status_text == 'Joined'"
                                            @click.stop="GoMedia('mo', data_info.motom_user_id)"><svg-icon
                                                name="link"></svg-icon></div>
                                    </div>
                                    <div class="name">Motom Status</div>
                                </div>
                            </template>
                        </CreatorHeaderSmallBox>
                        <!--tiktok -->
                        <CreatorHeaderSmallBox :data_info="data_info" :top_value="data_info.tiktok_handle"
                            v-if="data_info.tiktok_handle" :content_value="header_content_value.tiktok" :had_soc_status="true" :soc_status="data_info.tiktok_connect_status"/>
                        <!--ig -->
                        <CreatorHeaderSmallBox :data_info="data_info" :top_value="data_info.instagram_handle"
                            v-if="data_info.instagram_handle" :content_value="header_content_value.ig" :had_soc_status="true" :soc_status="data_info.instagram_connect_status"/>

                    </div>
                    <!-- v-if="$store.state.user_data.merchant_account.is_enable_ambassador_program && data_info.ambassador_program_tag" -->
                    <div class="program-tag-box" :key="tag_ref_key">
                        <dl>
                            <dt><svg-icon name="tag" /> Tag</dt>
                            <!-- <dd><span>{{ data_info.ambassador_program_tag }}</span></dd> -->
                            <dd v-if="data_info.merchant_account_tags !== undefined" @mouseover="data_info.hover_tag_list_box=true" @mouseleave="data_info.hover_tag_list_box = false" :class="['program-tag-box-dd', (data_info.hover_tag_list_box || data_info.edit_tag_list_box) && 'edit-program-tag-box-dd']" >
                                <div @click.stop="data_info.tag_open= !data_info.tag_open;data_info.tag_all_open=false;" class="add-tag-btn" v-show="data_info.hover_tag_list_box || data_info.edit_tag_list_box"><svg-icon name="tag-plus" /></div>
                                <Badage class="tag-item-box" v-for="(v,k) in data_info.merchant_account_tags.filter((ee,kk)=>{return kk < 5 })" :key="k" :value="v.text" :show_tippy="true">
                                    <svg-icon v-show="data_info.hover_tag_list_box || data_info.edit_tag_list_box" name="close" @click.stop="handleSelectTag({tag:v, row:data_info },'from_outside')" />
                                </Badage >
                                <Badage @click.stop="data_info.tag_all_open= !data_info.tag_all_open;data_info.tag_open=false;" v-if="data_info.merchant_account_tags.length - 5 > 0" :value="`+${ data_info.merchant_account_tags.length - 5}`"></Badage>
                            </dd>
                        </dl>
                        <!-- 開關tag_open -->
                        <DropdownHashtag :tags="data_info.tags" :selected_tags="data_info.merchant_account_tags"
                            :tag_type="'creator_tag'"
                            @select-tag="selectTag"
                            @closeKey="data_info.tag_open=false"                          
                            :open_key="data_info.tag_open"
                            :row="data_info"
                            :drop_css="'top:30px;left:120px'"
                            :index="9999"
                            />
                        <!-- 開關tag_all_open -->
                        <HashtagPopup 
                            :tags="data_info.merchant_account_tags"
                            :row="data_info" 
                            :open_key="data_info.tag_all_open"
                            :drop_css="'top:30px;left:120px'"
                            :index="9999"
                            @closeKey="data_info.tag_all_open=false;"
                            @open-cre-tag="data_info.tag_open=true;data_info.tag_all_open=false;"
                        />
                    </div>
                </div>
            </div>
            
            <div class="hint" v-if="is_dis_user">
                <!-- v-if="data_statistic.short_link_click_sum_trend !== 'same'" -->
                <SvgIcon name="alert" class="disabled-alert" />
                This storefront is not currently active and cannot generate any performance metrics including sales while disabled.
            </div>

            <Navi :menu="menu" :type="1" @selected-tab="selectedTab" v-if="data_info"/>
            <!-- <div class="scroll-box">
                <time class="upd-time" v-if="selected_tab_index === 0 || selected_tab_index === 1 ">Updated: {{last_update}}</time>
            </div> -->

            <div id="scroll-content-left" v-if="selected_tab_index === 0">
                <!-- :init_sortlink="sortlink_data" 在modalSide共用傳來傳去 -->
                <StorefrontSummary  :show_date_picker="true" :user_id="user_id"
                    :last_update="last_update" :is_show_posts="true" :date_range="date_range"
                    :products_num="data_products" @update-creator-modal-date="updateModalDate"
                    :api_url="summary_api_url" @update-data="getSummaryApiData" @handleSortLink="sortLinkClick"
                    :init_sortlink="sortlink_data"
                    />

                <div class="short-link"
                    v-if="data_statistic && (data_statistic.short_link_count + data_statistic.short_link_click_sum + data_statistic.short_link_sales_sum + data_statistic.short_link_sales_value_sum) > 0">
                    <header class="title-box">
                        <h2 class="title">Affiliate Shortlink Summary
                            <tippy :delay="[0, 20]" placement="right" trigger="mouseenter click" 
                                :content="`Creators can share direct links to products on their storefronts called affiliate shortlinks. Their clicks, sales, and sales value are reflected in the storefront summary.`">
                                <svg-icon name="question" />
                            </tippy>
                        </h2>
                        <!-- <h2 class="title">Affiliate Shortlink Summary</h2> -->
                    </header>
                    <div class="cre-report-box">
                        <div class="report-item">
                            <div class="value" v-if="data_statistic.short_link_count === 0">---</div>
                            <div class="value" v-else-if="data_statistic.short_link_count">{{
        data_statistic.short_link_count.toLocaleString('en-US') }}
                                <SvgIcon name="arrow-up" v-if="data_statistic.page_views_trend !== 'same'"
                                    :class="data_statistic.page_views_trend" />
                            </div>
                            <div class="text">Shortlink(s)</div>
                        </div>
                        <div class="report-item">
                            <div class="value" v-if="data_statistic.short_link_click_sum === 0">---</div>
                            <div class="value" v-else-if="data_statistic.short_link_click_sum">{{
        data_statistic.short_link_click_sum.toLocaleString('en-US') }}
                                <SvgIcon name="arrow-up" v-if="data_statistic.short_link_click_sum_trend !== 'same'"
                                    :class="data_statistic.short_link_click_sum_trend" />
                            </div>
                            <div class="text">Clicks</div>
                        </div>
                        <div class="report-item">
                            <div class="value" v-if="data_statistic.short_link_sales_sum === 0">---</div>
                            <div class="value" v-else-if="data_statistic.short_link_sales_sum">{{
        data_statistic.short_link_sales_sum.toLocaleString('en-US') }}
                                <SvgIcon name="arrow-up" v-if="data_statistic.short_link_sales_trend !== 'same'"
                                    :class="data_statistic.short_link_sales_trend" />
                            </div>
                            <div class="text">Sales</div>
                        </div>
                        <div class="report-item">
                            <!-- formatMoney -->
                            <div class="value" v-if="data_statistic.short_link_sales_value_sum === 0">---</div>
                            <div class="value" v-else>${{ Math.ceil(data_statistic.short_link_sales_value_sum).toLocaleString('en-US') }}
                                <SvgIcon name="arrow-up" v-if="data_statistic.short_link_sales_value_trend !== 'same'"
                                    :class="data_statistic.short_link_sales_value_trend" />
                            </div>
                            <div class="text">Sales Value</div>
                        </div>
                    </div>
                </div>
                <div class="doughnuts">
                    <div class="doughnut-box">
                        <div class="title">
                            <span>Traffic Source</span>
                            <Dropdown :items="doughnut_select" :direction_type="'right'" :icon_direction_type="'right'"
                                @update-dropdown="getDoughnutSource" ref="dropdown_source" />
                        </div>
                        <Loading class="static" v-if="is_loading_source" />
                        <Doughnut v-else :color="source_doughnut_color" ref="source" :key="is_loading_source" />
                    </div>
                    <div class="doughnut-box">
                        <div class="title">
                            <span>Operating Systems</span>
                            <Dropdown :items="doughnut_select" :direction_type="'right'" :icon_direction_type="'right'"
                                @update-dropdown="getDoughnutDevice" ref="dropdown_device" />
                        </div>
                        <Loading class="static" v-if="is_loading_device" />
                        <Doughnut v-else :color="device_doughnut_color" ref="device" :key="is_loading_device" />
                    </div>
                </div>

                <ProductList :title="top_performing.title" :data="data_products" :date_range="date_range" :key="ref_pro_key"
                    :sort_index="product_list_sort_index" :is_loading="is_loading_products"
                    :is_loading_page="is_loading_products_page" @update-sort="getProductList"
                    :no_data_context="{ title: top_performing.context.title, content: top_performing.context.content }"
                    :data_info="data_info" :is_swiper="true" :init_sortlink="sortlink_data" :show_data_statistic="false"
                    :data_statistic="data_statistic"
                    />
            </div>

            <div id="scroll-content-right" v-if="selected_tab_index === 1">
                <!-- :init_sortlink="sortlink_data" 在modalSide共用傳來傳去 -->
                <ProductList :title="`All Products (${data_products.length})`"
                    :key="data_statistic"
                    :data="data_products" :date_range="date_range" :sort_index="product_list_sort_index"
                    :is_loading="is_loading_products" @update-sort="getProductList" @updateDate="updateModalDate"
                    :is_loading_page="is_loading_products_page" :data_info="data_info"
                    :show_date_picker="true" :show_sortlink="true"
                    :data_statistic="data_statistic" @handleSortLink="sortLinkClick"
                    :no_data_context="{ title: 'No product data available', content: 'Once this creator adds items to their storefront, their product analytics will appear here' }" 
                    :init_sortlink="sortlink_data"/>

            </div>

            <div id="scroll-content-collection" v-if="selected_tab_index === 2">
                <CollectionList :date_range="date_range" :data_info="data_info" :key="date_range"
                    @update-creator-modal-date="updateModalDate" :is_show_hint="is_dis_user" />
            </div>

            <div id="scroll-content-content" v-if="selected_tab_index === 3">
                <BrandPosts :key="date_range" :user_id="user_id" :data_info="data_info" :date_range="date_range" :ref="brand_posts" @update-creator-modal-date="updateModalDate"/>
            </div>
            <div id="scroll-content-social" v-if="selected_tab_index === 4" :key="selected_tab_index">
                <SocialStats :date_range="date_range" :data_info="data_info" :key="date_range"
                    :last_update="last_update" @update-creator-modal-date="updateModalDate"
                    :is_show_hint="is_dis_user" />
            </div>
        </div>
    </ModalSide>
    <EditCreator ref="edit" @save="saveEdit" />
    <Modal :is_show="is_show_alert_modal" class="alert-modal" @click-bg="is_show_alert_modal = false">
        <div class="box">
            <header class="delete-header" v-if="data_info.anchor_page_status_text === 'Active'">Deactivate
                storefront(s)?
            </header>
            <header class="delete-header" v-else>Enable storefront(s)?</header>
            <p v-if="data_info.anchor_page_status_text === 'Active'">The storefront link(s) will no longer be active,
                but
                all of the data will remain in the Anchor portal.</p>
            <p v-else>1 selected creator ready to enable storefront, we are exclusive 3 error selection</p>

            <div class="btns">
                <Button type="button" color="no-color" size="32" @click.native="toggleStatus(1)">No</Button>
                <Button type="button" color="dark-blue" size="32" @click.native="toggleStatus(2)">Yes</Button>
            </div>
        </div>
    </Modal>

    <Modal :is_show="tag_remove_modal" class="alert-modal" @click-bg="tag_remove_modal = false">
        <div class="box remove-box">
            <header class="delete-header" >Remove tag?</header>
            <p style="word-break: break-all;">Are you sure you want to remove <br/>{{ handle_remove_tag_value.tag.text }}?</p>
            <div class="btns">
                <Button type="button" color="no-color" size="32" @click.stop="tag_remove_modal = false">No</Button>
                <Button type="button" color="dark-blue" size="32" @click.stop="tag_remove_modal = false;selectTag( handle_remove_tag_value ,'from_outside')">Yes, confirm</Button>
            </div>
        </div>
    </Modal>
</template>
<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Switch from '@/components/ui/Switch.vue';
import Navi from '@/components/Navi.vue';
import ModalSide from '@/components/ui/ModalSide.vue';
import StorefrontSummary from '@/components/summary/StorefrontSummary.vue';
import Dropdown from '@/components/ui/Dropdown.vue';
import Doughnut from '@/components/summary/Doughnut.vue';
import ProductList from '@/components/product/ProductList.vue';
import EditCreator from '@/components/creator/EditCreator.vue';
import Modal from '@/components/ui/Modal.vue';
import Button from '@/components/ui/Button.vue';
import formatMoney from '@/utils/formatMoney';
import moment from 'moment';
import NoData from '@/components/creator/NoData.vue';
import Loading from '@/components/ui/Loading.vue';
import { goMedia } from '@/utils/goMedia';
import { makeStatusFunction } from '@/utils/ShareEvent';
import CreatorHeaderSmallBox from '@/components/creator/CreatorHeaderSmallBox.vue';
import CollectionList from '@/components/creator/CollectionList.vue';
import SocialStats from '@/components/creator/SocialStats.vue';
import BrandPosts from '@/components/BrandPosts.vue';
import Badage from '@/components/ui/Badage.vue';
import DropdownHashtag from '@/components/DropdownHashtag.vue';
import HashtagPopup from '@/components/HashtagPopup.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import { HandleNum, HandleNumSpec } from '@/utils/SwitchFun';
import { getTags, handleCreTag, selectTag } from '@/utils/CreatorTagFun';
import Checkbox from '@/components/ui/Checkbox.vue';

export default {
    name: 'CreatorModalSide',

    components: {
        SvgIcon,
        Switch,
        Navi,
        ModalSide,
        StorefrontSummary,
        Dropdown,
        Doughnut,
        ProductList,
        EditCreator,
        Button,
        Modal,
        NoData,
        Loading,
        CreatorHeaderSmallBox,
        CollectionList,
        SocialStats,
        BrandPosts,
        Badage,
        DropdownHashtag,
        HashtagPopup,
        DatePicker,
        Checkbox,
    },

    data() {
        return {
            temp_loading: false,
            editing_name: false,
            is_show_alert_modal: false,
            is_loading_products: true,
            is_loading_products_page: false,
            user_id: '',
            last_update: '',
            start_date: '',
            end_date: '',
            data_info: null,
            data_statistic: null,
            data_source: [],
            data_device: [],
            data_products: [],
            // data: null,
            menu: [],
            selected_tab_index: 0,
            product_list_sort_index: 0,
            product_list_sort_value: 1,
            total_views: 0,
            total_clicks: 0,
            total_sales: 0,
            total_transactions: 0,
            source_doughnut_color: ['#C32AA3', '#27364B', '#FAA378', '#1DB954', '#FF0000'],
            device_doughnut_color: ['#1DA1F2', '#4F4F4F', '#1DB954', '#512bcd', '#ec5ce7'],
            doughnut_select: [
                { id: 1, text: 'Views' },
                { id: 2, text: 'Clicks' },
                { id: 3, text: 'Sales' },
                { id: 4, text: 'Sales Value' },
            ],
            is_loading_source: true,
            is_loading_device: true,
            current_page: 1,
            last_page: 1,
            top_performing: {
                title: "Top Performing Products",
                context: {
                    title: "No data available",
                    content: "There's not enough performance data to determine top performing products yet."
                }
            },
            sourceList: [
                'instagram',
                'tiktok',
                'youtube',
                'motom',
                'search',
                'other',
                'linktree',
                'beacons',
            ],
            deivceList: [
                'android',
                'ios',
                'windows',
                'other',
            ],
            summary_api_url: '/creator/creator-detail-statistic',
            header_content_value: {
                email: {
                    icon: 'email',
                },
                tiktok: {
                    icon: 'tiktok-pure',
                    show_val:'tt',
                    go_val:'tk',
                    under: [
                        {
                            name: 'Followers',
                            content: '--'
                        },
                        {
                            name: 'Posts',
                            content: '--'
                        },
                        {
                            name: 'Engagement',
                            content: '--'
                        }
                    ]
                },
                ig: {
                    icon: 'instagram',
                    show_val:'ins',
                    go_val:'ig',
                    under: [
                        {
                            name: 'Followers',
                            content: '--'
                        },
                        {
                            name: 'Posts',
                            content: '--'
                        },
                        {
                            name: 'Engagement',
                            content: '--'
                        }
                    ]
                },
                storefront: {
                    icon: 'storefront',
                    show_val:'',
                    go_val:'mo',
                    under: [
                        {
                            name: 'Last Update',
                            content: '--'
                        },
                        {
                            name: 'Active Date',
                            content: '--'
                        },
                    ]
                },
                
            },
            ref_pro_key:0,
            is_first_open:false,
            all_tags:[],
            tag_ref_key:0,
            tag_remove_modal:false,
            handle_remove_tag_value:{//抓住準備要unbind的tag和creator
                row:null,
                tag:''
            },
            sortlink_data:0,
        }
    },

    computed: {
        date_range() {
            return {
                start_date: this.start_date,
                end_date: this.end_date
            }
        },
        is_dis_user() {
            return this.data_info && this.data_info.anchor_page_status_text === 'Disabled';
        },

        user_handle_value() {
            return {
                only_one: !this.data_info.tiktok_handle || !this.data_info.instagram_handle,
                no_one: (!this.data_info.tiktok_handle && !this.data_info.instagram_handle),
                all_had: this.data_info.tiktok_handle && this.data_info.instagram_handle,
            }
        }
    },

    methods: {
        formatMoney: formatMoney,
        GoMedia: goMedia,

        sortLinkClick(val){
            this.sortlink_data=val;
            console.log("this.sortlink_data",this.sortlink_data)
            this.data_products=[];
            this.current_page=1;
            this.getProductList();
            if(this.selected_tab_index===1){
                this.getStatistic();
            }
        },

        start() {
            if (this.data_info) {
                this.header_content_value.storefront.under[0].content = this.$filters.momentFormat(this.data_info.last_store_front_update_at, 'll');
                this.header_content_value.storefront.under[1].content = this.$filters.momentFormat(this.data_info.show_status_time, 'll');

                this.header_content_value.tiktok.under[0].content = HandleNum(this.data_info.tiktok_follower_count) ;
                this.header_content_value.tiktok.under[1].content = HandleNum(this.data_info.tiktok_post_count) ;
                this.header_content_value.tiktok.under[2].content = HandleNumSpec(this.data_info.tiktok_engagement) !== '---' ? `${HandleNumSpec(this.data_info.tiktok_engagement)}%` : HandleNumSpec(this.data_info.tiktok_engagement);

                this.header_content_value.ig.under[0].content = HandleNum(this.data_info.instagram_follower_count);
                this.header_content_value.ig.under[1].content = HandleNum(this.data_info.instagram_post_count);
                this.header_content_value.ig.under[2].content = HandleNumSpec(this.data_info.instagram_engagement) !== '---' ? `${HandleNumSpec(this.data_info.instagram_engagement)}%` : HandleNumSpec(this.data_info.instagram_engagement);

                this.last_update = this.$filters.momentFormat(this.data_info.last_store_front_update_at, 'll');
                // console.log(this.last_update)

            }
        },

        async getTags() {
            let tagData= await getTags(this);
            if(tagData.data){
                this.all_tags=tagData.orgData;
            }
        },

        getDoughnut() {
            //this.is_loading_device = false;
            // this.is_loading_source = false;

            setTimeout(() => {
                if (this.$refs.dropdown_source){
                    this.$refs.dropdown_source.selected_index = 0;
                }
                if (this.$refs.dropdown_device){
                    this.$refs.dropdown_device.selected_index = 0;
                }
                console.log('getDoughnut')
                this.getDoughnutSource();
                this.getDoughnutDevice();
            }, 100);
        },

        getDoughnutSource(obj) {
            this.is_loading_source = true;
            let sort_key = 'page_views';
            if (obj) {
                switch (obj.id) {
                    case 1:
                        sort_key = 'page_views';
                        break;

                    case 2:
                        sort_key = 'click';
                        break;

                    case 3:
                        sort_key = 'sales';
                        break;

                    case 4:
                        sort_key = 'sales_value';
                        break;

                    default:
                        break;
                }
            }

            let api = `/analytics/break-down/source`;
            api = this.getApiParamStr(api) + `&sort_key=${sort_key}`;

            this.$ajax(api, {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                this.data_source = res.data.data;
                this.is_loading_source = false;

                //handle if name is abbr
                let handleData = res.data.data.map(e => {
                    let index = -1
                    if (e.name.length == 1) {
                        index = this.sourceList.findIndex(ee => { return ee[0] == e.name })
                    }
                    return {
                        ...e,
                        name: index !== -1 ? this.sourceList[index] : e.name
                    }
                })


                this.$nextTick(() => {
                    if (this.$refs.source) this.$refs.source.update({
                        type: sort_key,
                        data: handleData
                    });
                })
            });
        },

        resendInvite() {
            // this.data_info.anchor_page_status = 20;
            // this.data_info.anchor_page_status_text = 'Invited';
            this.$ajax('/creator/update-anchor-page-status', {
                method: 'post',
                data: {
                    anchor_creator_id: this.data_info.id,
                    status: 20
                },
            }).then(res => {
                this.data_info.anchor_page_status = 20;
                this.data_info.anchor_page_status_text = 'Invited';
            });
        },
        getDoughnutDevice(obj) {
            this.is_loading_device = true;
            let sort_key = 'page_views';
            if (obj) {
                switch (obj.id) {
                    case 1:
                        sort_key = 'page_views';
                        break;

                    case 2:
                        sort_key = 'click';
                        break;

                    case 3:
                        sort_key = 'sales';
                        break;

                    case 4:
                        sort_key = 'sales_value';
                        break;

                    default:
                        break;
                }
            }
            let s = moment(this.start_date).format('YYYY-MM-DD');
            let e = moment(this.end_date).format('YYYY-MM-DD');
            let api = `/analytics/break-down/device-use`;
            api = this.getApiParamStr(api) + `&sort_key=${sort_key}`;

            this.$ajax(api, {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                this.data_device = res.data.data;
                this.is_loading_device = false;

                //handle if name is abbr
                let handleData = res.data.data.map(e => {
                    let index = -1
                    if (e.name.length == 1)
                        index = this.deivceList.findIndex(ee => { return ee[0] == e.name });

                    return {
                        ...e,
                        name: index !== -1 ? this.deivceList[index] : e.name
                    }
                });

                this.$nextTick(() => {
                    if (this.$refs.device) this.$refs.device.update(
                        {
                            type: sort_key,
                            data: handleData
                        }
                    );
                })
            });
        },

        downloadPdf() {
            this.$notify({
                title: 'Coming soon',
                type: 'warn',
                duration: 3000,
                group: 'center',
            });
        },
        toggleStatus(type) {
            this.is_show_alert_modal = false;
            if (type === 2) {
                this.$ajax('/creator/update-creator-status', {
                    method: 'post',
                    data: {
                        anchor_creator_id: this.user_id,
                        status: (this.data_info.anchor_page_status_text === 'Active') ? 0 : 100
                    },
                    withCredentials: true,
                }).then(res => {
                    if (res.data.status === 'success') {
                        this.data_info.anchor_page_status = res.data.data.new_status;

                        if (res.data.data.new_status === 0) {
                            this.data_info.anchor_page_status_text = 'Disabled';
                            this.data_info.show_status_time = new Date();
                        } else if (res.data.data.new_status === 100) {
                            this.data_info.anchor_page_status_text = 'Active';
                            this.data_info.show_status_time = this.data_info.accept_store_front_datetime;
                        }

                        this.EventBus.emit('sync-creator-data', this.data_info);
                        this.$store.commit('check_limit', this);
                    }
                }).catch(err => {
                });
            }
        },

        updateModalDate(obj) {
            this.start_date = moment(obj.start_date).format('YYYY-MM-DD');
            this.end_date = moment(obj.end_date).format('YYYY-MM-DD');
            if (this.selected_tab_index !== 2 ) {
                this.getDoughnut();
                this.data_products=[];
                this.current_page=1;
                this.getProductList();
                this.getStatistic();
            }
        },

        // editText(type) {
        //     console.log(type);
        // },

        editInfo() {
            this.$refs.edit.open(this.data_info);
        },

        saveEdit(vals) {
            // console.log(vals)
            this.data_info.storefront_state = vals.state,
                this.data_info.first_name = vals.first_name,
                this.data_info.last_name = vals.last_name,
                this.data_info.anchor_page_status = vals.anchor_page_status,
                this.data_info.anchor_page_status_text = vals.anchor_page_status_text,
                this.data_info.tiktok_handle = vals.tiktok_handle ? vals.tiktok_handle : '',
                this.data_info.instagram_handle = vals.instagram_handle ? vals.instagram_handle : '',
                this.data_info.is_allow_customize_collection_cover = vals.is_allow_customize_collection_cover;

            this.EventBus.emit('sync-creator-data', this.data_info);
            // this.data.youtube = vals.youtube ? vals.youtube : '',
            this.$notify('<svg><use xlink:href="#yes--sprite"></use></svg> Saved successfully.');
        },

        selectedTab(index) {
            this.selected_tab_index = index;
            if (index === 0) {
                setTimeout(() => {
                    this.current_page = 1;

                    this.getDoughnut();
                    if (this.$refs.source && this.data_source.length > 0 && this.$refs.source.update){
                        this.$refs.source.update(this.data_source);
                    }
                    if (this.$refs.device && this.data_device.length > 0 && this.$refs.device.update){
                        this.$refs.device.update(this.data_device);
                    }


                }, 100);
            } else if (index === 1) {
                this.getStatistic();
            }
        },

        clickSwitch() {
            if (this.data_info.anchor_page_status_text === 'Active')
                this.is_show_alert_modal = true;
            else
                this.toggleStatus(2);
        },

        getApiParamStr(api) {
            return `${api}?creator_user_id=${this.user_id}&start_date=${this.start_date}&end_date=${this.end_date}`;
        },
        
        getInfo() {
            let api = `/creator/get-creator-detail`;
            api = this.getApiParamStr(api);
            this.$ajax(api, {
                method: 'get',
            }).then(res => {
                this.data_info = res.data.data;
                handleCreTag(this.data_info, this.all_tags);
                
                //console.log('this.data_info',this.data_info)
                //-------------------
                this.$store.state.creator_data=this.data_info;
                this.data_info.show_status_time = makeStatusFunction(this.data_info);
                this.start();
                if (!this.data_info.userProfile) {
                    this.data_info.userProfile = {
                        avatar_full_url: require('@/assets/images/user.png')
                    }
                }
            });
        },

        //not useing at tab0, because data get in summary template
        getStatistic() {
            let api = this.summary_api_url;
            //因為不管如何我都要先知道有sortlink=1的時候有沒有資料，所以這邊強行都CALL 1，但顯示不顯示由前端操控
            api = this.getApiParamStr(api) + `&is_include_shortlink_data=1`;
            this.$ajax(api, {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                this.data_statistic = res.data.data;
            });
        },

        //data had call in summary template
        getSummaryApiData(res){
            this.data_statistic = res.data;
        },

        getProductList(obj) {
            this.is_loading_products = true;

            if (this.current_page > 1 && document.getElementById('modal-body'))
                document.getElementById('modal-body').scrollTop = document.getElementById('modal-body').scrollTop - 50;
            let api = `/creator/product-list`;
            api = this.getApiParamStr(api) + `&page=${this.current_page}&is_include_shortlink_data=${this.sortlink_data}`;
            let sort_key = '';
            let sort_val = '';
            if (obj) {
                this.product_list_sort_index = obj.type.id - 1;
                this.product_list_sort_value = obj.type.id;
                this.current_page = 1;
                this.start_date = obj.date.start;
                this.end_date = obj.date.end;
            }

            if (this.current_page > 1) this.is_loading_products_page = true;
            //++++++++++++++++++++++++++++++++++++++++++++++++++++++++
            //handle sort and date
            //++++++++++++++++++++++++++++++++++++++++++++++++++++++++
            switch (this.product_list_sort_value) {
                case 1:
                    sort_key = 'views';
                    sort_val = 'desc';
                    break;
                case 2:
                    sort_key = 'views';
                    sort_val = 'asc';
                    break;

                case 3:
                    sort_key = 'click';
                    sort_val = 'desc';
                    break;

                case 4:
                    sort_key = 'click';
                    sort_val = 'asc';
                    break;

                case 5:
                    sort_key = 'sales';
                    sort_val = 'desc';
                    break;
                case 6:
                    sort_key = 'sales';
                    sort_val = 'asc';
                    break;

                case 7:
                    sort_key = 'sales_value';
                    sort_val = 'desc';
                    break;

                case 8:
                    sort_key = 'sales_value';
                    sort_val = 'asc';
                    break;

                default:
                    break;
            }

            let newApi = `/creator/product-list?creator_user_id=${this.user_id}&start_date=${this.start_date}&end_date=${this.end_date}` + `&page=${this.current_page}`;
            api = `${newApi}&sort_key=${sort_key}&sort_val=${sort_val}&is_include_shortlink_data=${this.sortlink_data}`;


            this.$ajax(api, {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                
                if (this.current_page === 1)
                    this.data_products = res.data.data;
                else
                    res.data.data.forEach(p => {
                        this.data_products.push(p);
                    });
                
                this.current_page = Number(res.data.current_page);
                this.last_page = Number(res.data.last_page);
                this.$nextTick(() => {
                    this.is_loading_products = false;
                    this.is_loading_products_page = false;
                    this.ref_pro_key+=1;
                    //目前新增以下三欄位但沒用到8882
                    // (new field) sale_value_include_shortlink_data
                    // (new field) sales_include_shortlink_data
                    // (new field) click_include_shortlink_data
                })

            });
        },

        nextProductListPage(type) {
            if (!this.is_loading_products && this.current_page < this.last_page) {
                this.current_page++;
                this.getProductList();
            }
        },

        async open() {
            //refrash data
            console.log("OPEN???!")
            this.sortlink_data = this.$store.state.save_sortlink_data;//放入預設
            this.current_page = 1;
            this.data_products = [];
            this.data_statistic = null;
            this.is_first_open = true;
            this.menu = [
                {
                    text: 'Storefront',
                    to: '/analytics/overview',
                },
                {
                    text: 'Products',
                    to: '/analytics/product',
                },
                {
                    text: 'Collections',
                    to: '/analytics/collections',
                },
                {
                    text: 'Content (beta)',
                    to: '/analytics/content',
                },
                {
                    text: 'Social Insights (beta)',
                    to: '/analytics/social-stats',
                },
            ];

            
            await this.getTags();
            this.selectedTab(0);//Doughnut call in here
            this.getInfo();
            this.getProductList();
            
            this.$refs.modal.open();
            this.$store.state.creator_data_open=true;
        },

        close() {
            this.$store.state.creator_data_open=false;
            this.$refs.modal.close();
        },

        viewStorefront(share_link) {
            //+`?from_anchor=true`
            if (share_link)
                window.open(share_link, '_blank');
        },

        share() {
            if (this.data_info.share_link) {
                const obj = {};
                obj.url = 'test';
                obj.storefront_url = this.data_info.share_link;
                this.EventBus.emit('open-share', obj);
            }
        },

        imageError(event) {
            event.target.src = require('@/assets/images/user.png');
        },

        emailTo(email) {
            window.location.href = `mailto:${email}`;
        },

        tempBtnForFb() {
            this.temp_loading = true;
            this.$ajax(`/creator/fetch-third-party-insight-api?motom_user_id=${this.data_info.motom_user_id}&merchant_account_id=${this.$store.state.user_data.merchant_account_id}`, {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                window.location.reload();
                this.temp_loading = false;
            }).catch(err => {
                window.location.reload();
                this.temp_loading = false;
            })
        },

        //這邊會處理單人的tag刪減增加,API處理會在元件裡面做完(除了有spec_text的要另外CALL)
        selectTag(item,spec_text){
            selectTag( item, spec_text, this); 
        },

        handleSelectTag(item){
            this.handle_remove_tag_value.row=item.row;
            this.handle_remove_tag_value.tag=item.tag;
            this.tag_remove_modal=true;
        },
    },

    mounted() {
        this.EventBus.off('open-creator-modal');
        this.EventBus.off('close-creator-modal');

        this.EventBus.on('open-creator-modal', (obj) => {
            this.user_id = obj.user_id;
            this.$store.state.pick_creator_user_id = obj.user_id;
            this.start_date = moment(obj.start_date).format('YYYY-MM-DD');
            this.end_date = moment(obj.end_date).format('YYYY-MM-DD');
            this.open();
        });

        this.EventBus.on('close-creator-modal', () => {
            this.$store.state.creator_data_open=false;
            this.$refs.edit.close();

            setTimeout(() => {
                this.close();
            }, 300);
        });

        this.EventBus.off('update-modal-creator');
        this.EventBus.on('update-modal-creator', (user) => {

            if(user){
                console.log('user_id',user)
                this.data_info = null;
                this.data_products=[];
                this.data_statistic = null;
                this.current_page = 1;
                this.user_id = user.id;
                this.$store.state.pick_creator_user_id = user.id;
                this.selectedTab(0);//Doughnut call in here
                this.getProductList();
            }

            this.getTags();
            this.getInfo();
        });

    },
}
</script>
<style lang="scss">
.creator-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;

    .text-ellipsis {
        white-space: nowrap;
        width: 100%;
        max-width: 200px;
        min-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
    }

    .text-ellipsis-link {
        cursor: pointer;

        a {
            color: var(--black-90) !important;
        }
    }

    .text-ellipsis-link:hover {
        text-decoration: underline;
    }

    .ui-modal-side--container {

        header {
            .icon-box .icon {
                padding: 12px 8px;
                margin-left: 8px;

                a {
                    color: var(--black-75);

                    &:hover {
                        color: var(--blue);
                    }
                }

                &.close {
                    padding: 12px;
                }

                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .ui-navi {
            margin-top: 16px;
        }
        
        .scroll-box{
            position:relative;
        }
        .upd-time{
            font-size: 12px;
            color: var(--black-75);
            position: absolute;
            right: 0px;
            top: -28px;
        }

        .title {
            &.editable {
                cursor: pointer;

                &:hover {
                    background: var(--black-10);
                }
            }

            .edit-input {
                display: flex;

                input {
                    padding: 5px 8px;
                    border: 1px solid var(--black-20);
                    background-color: white;
                    width: 100%;
                    border-radius: 6px;
                    color: var(--black-90);
                    transition: .15s border-color ease;
                    font-weight: 500;
                    font-family: var(--font);

                    &:hover {
                        border-color: var(--black-50);
                    }

                    &:focus {
                        outline: none;
                        border-color: var(--dark-blue);
                    }

                    &::placeholder {
                        color: var(--black-50);
                        font-weight: 500;
                    }
                }

                .button {
                    padding: 0 8px;
                    background-color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        width: 12px;
                        height: 12px;
                        fill: var(--black-60);
                    }

                    &:hover>svg {
                        fill: var(--black-80);
                    }
                }
            }
        }

        .creator-info-box {
            margin-top: 16px;
            padding: 16px 0;
            display: flex;
            align-items: center;

            .avatar {
                margin-right: 16px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 170px;
                    height: 170px;
                    border-radius: 50%;
                    border: 1px solid var(--black-40);
                }
            }

            .creator-info {
                // display: flex;
                // flex-direction: column;
                // justify-content: center;
                flex: 1;

                .creator-data-box {
                    display: flex;
                    position: relative;
                    margin: 0 -4px;

                    .creator-data {
                        width: calc(33.33% - 8px);
                        margin: 0 4px;
                        border-radius: 6px;
                    }

                    &.creator-data-box-only-one {
                        .creator-data {
                            width: calc(50% - 8px);
                            margin: 0 4px;
                        }
                    }

                    &.creator-data-box-no-one {
                        .creator-data {
                            width: 100%;
                            margin: 0;
                        }
                    }
                    
                }

                .program-tag-box {
                    border-radius: 6px;
                    margin: 8px 0 0;
                    display: flex;
                    align-items: center;
                    padding: 0 16px;
                    height: 38px;
                    background-color: var(--bg-10);
                    position:relative;
                    cursor: pointer;

                    dl {
                        display: flex;
                        align-items: center;
                        width:100%;
                    }

                    dt {
                        font: var(--font-med-14);
                        color: var(--black-90);
                        display: flex;
                        align-items: center;
                        
                        svg {
                            width: 20px;
                            height: 20px;
                            fill: var(--black-90);
                            margin-right: 6px;
                        }
                    }

                    dd {
                        padding-left: 12px;
                        display: flex;
                        align-items: center;

                        span {
                            display: inline-flex;
                            height: 24px;
                            border-radius: 4px;
                            background-color: white;
                            padding: 0 6px;
                            align-items: center;
                            font-size: 12px;
                        }


                        &.program-tag-box-dd{
                            cursor:pointer;
                            display:flex;
                            align-items: center;
                            padding:4px;
                            gap:6px;
                            margin-left:6px;
                            flex:1;
                            box-sizing: border-box;
                            height: 36px;

                            &.edit-program-tag-box-dd{
                                border:solid 1px var(--neutral);
                                border-radius:6px;
                            }

                            .my-badges-iden{
                                display:flex;
                                align-items: center;
                                border-radius: 6px;

                                svg {
                                    height: 8px;
                                    width: 8px;
                                    margin-left: 6px;
                                }

                                span{
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    max-width: 100px;
                                    background:unset;
                                    display:unset;
                                    height: unset;
                                    padding: unset;
                                }
                            }

                            .add-tag-btn{
                                padding: 4px;
                                border:solid 1px var(--neutral);
                                border-radius:6px;
                                height: 24px;
                                display: flex;
                                align-items: center;

                                .svg-icon{
                                    margin: unset;
                                    width: 14px;
                                    height: 14px;
                                }
                            }
                        }
                    }
                }
            }

            &.no-program {

                .avatar {
                    img {
                        width: 120px;
                        height: 120px;
                    }
                }
            }
        }

        .hint {
            margin-top: 16px;
            padding: 8px;
            font: var(--font-med-12);
            background: var(--bg-11);
            color: var(--black-75);
            display: flex;
            align-items: center;

            .disabled-alert {
                width: 12px;
                height: 12px;
                margin-right: 10px;
            }
        }

    }

    .doughnuts {
        display: flex;
        margin-bottom: 24px;

        .doughnut-box {
            padding: 12px;
            border: 1px solid var(--border);
            border-radius: 10px;
            // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            width: calc(50% - 8px);

            &:nth-child(1) {
                margin-right: 8px;
            }

            &:nth-child(2) {
                margin-left: 8px;
            }

            .title {
                display: flex;
                align-items: center;
                font: var(--font-demi-20);
                color: var(--black-90);

                span {
                    flex: 1;
                }

                .dropdown {
                    width: fit-content;
                }
            }

        }
    }

    .conent-time-top{
        display: flex;
        align-items: center;
        margin: 16px 0;
        pointer-events: none;
        justify-content: flex-end;
        position: relative;
        //margin-top: -36px;
        height: 32px;

        & * {
            pointer-events: auto;
        }

        time {
            font-size: 12px;
            color: var(--black-75);
            padding-right: 12px;
            margin-left: 8px;
        }
    }

    .cre-report-box {
        margin-top: 8px;
        margin-bottom: 24px;
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.08);

        .report-item {
            padding: 24px 16px;
            width: 25%;
            background: white;
            border-right: 1px solid rgba(0, 0, 0, 0.08);

            &:last-child{
                border-right: none;
            }
            
            .value {
                font:var(--font-med-24);
                color: var(--dark-blue);
                display: flex;
                align-items: center;


                svg {
                    margin-left: 10px;
                    width: 10px;
                    height: 10px;
                    fill: var(--green);

                    &.down {
                        fill: var(--red);
                        transform: rotate(90deg)
                    }
                }
            }

            .text {
                margin-top: 4px;
                font:var(--font-med-16);
                color: var(--text-secondary);
            }
        }
    }

    .short-link {
        margin-top: 8px;

        .title-box {
            display: flex;

            .title {
                font: var(--font-demi-20);
                color: var(--black-90);
                flex: 1;
                display: flex;

                svg{
                    cursor: pointer;
                    width: 14px;
                    height: 14px;
                    margin-top: 4px;
                }
            }
        }

    }

    .ui-product-list {
        margin-top: 20px;
    }


    .text-ellipsis-link {
        display: block !important;
    }

    .temp-css-in-title {
        display: flex;
        align-items: center;

        .ui-button {
            margin-left: 8px;
        }
    }
}
</style>